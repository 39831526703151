<template>
  <div class="soil-test max-w-lg">
    <validation-observer v-slot="{invalid}">
      <form-wizard
          :subtitle="null"
          :title="null"
          backButtonText="Précédent"
          class="p-0"
          color="var(--primary)"
          errorColor="var(--danger)"
          finish-button-text="Valider"
          nextButtonText="Suivant"
          stepSize="xs"
          @on-complete="$emit('testDone')">
        <tab-content title="Etape 1">
          <div class="d-flex flex-wrap">
            <img
                alt="bocal-terre"
                class="col-12 col-md-6 rounded-lg mx-auto mb-1 pr-25"
                src="@/assets/images/pictures/bocal/bocal_1.jpg">
            <img
                alt="bocal-terre"
                class="col-12 col-md-6 rounded-lg mx-auto mn-1 pl-25"
                src="@/assets/images/pictures/bocal/bocal_2.jpg">
            <p class="text-center font-medium-3 mt-1">
              Remplissez un grand bocal à moitié de votre sol, prélevé à entre 5-10cm de profondeur.
              Finissez de remplir le bocal d'eau.
            </p>
          </div>
        </tab-content>
        <tab-content title="Etape 2">
          <div class="d-flex flex-wrap align-items-center">
            <img
                alt="bocal-terre"
                class="col-12 col-md-6 rounded-lg mx-auto mb-1 pr-25"
                src="@/assets/images/pictures/bocal/bocal_3.jpg">
            <feather-icon
                class="col-12 col-md-6 rounded-lg mx-auto mb-1 pr-25"
                icon="ClockIcon"
                size="32"/>
            <p class="text-center font-medium-3 mt-1">
              Secouez vivement pendant 2 minutes, puis laissez reposer pendant au moins 24 heures.
            </p>
          </div>
        </tab-content>
        <tab-content :before-change="getResult" title="Etape 3">
          <div class="d-flex flex-wrap justify-center">
            <img
                alt="bocal-terre"
                class="col-12 col-md-6 rounded-lg mx-auto mb-1"
                src="@/assets/images/pictures/bocal/bocal_4.jpg">
            <img
                alt="bocal-terre"
                class="col-12 col-md-6 rounded-lg mx-auto mb-1"
                src="@/assets/images/pictures/bocal/bocal_4_zoom_with_notes.jpg">
            <p class="text-center font-medium-3 mt-1">
              Mesurez les niveaux des trois granulométries,
              <JayaTooltip
                  text="L'argile peut se pétrir en pâtons qui ne s'éffritent pas. Ce pâton colle aux doigts quand il est humide et peu se former en bodin assez fin. A l'état sec, l'argile forme des blocs durs.">
                argile
              </JayaTooltip>
              ,
              <JayaTooltip
                  text="Entre les doigts, le limon est doux avec une sensassion similaire au talc. Un sol limoneux est facilement malléable. A l'état sec, le limon est poussièreux et tâche les doigts ; des petits agrégats éclatent et se pulverisent sous la pression des doigts.">
                limon
              </JayaTooltip>
              et
              <JayaTooltip
                  text="Les sables grattent sous les doigts et émettent un crissement à l'oreille lorsque frotté. Les sables sont souvent composé de granulés qui se distinguent visuellement. ">
                sable
              </JayaTooltip>
              , puis entrez les ci-dessous.
            </p>
            <validation-provider v-slot="{errors}" name="Température" rules="min_value:0">
              <b-form-input
                  v-model="clay"
                  class="w-100 mb-50"
                  placeholder="Argile (mm)"
                  type="number"/>
              <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
            </validation-provider>

            <validation-provider v-slot="{errors}" name="Température" rules="min_value:0">
              <b-form-input
                  v-model="silt"
                  class="w-100 mb-50"
                  placeholder="Limon (mm)"
                  type="number"/>
              <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
            </validation-provider>

            <validation-provider v-slot="{errors}" name="Température" rules="min_value:0">
              <b-form-input
                  v-model="sand"
                  class="w-100 mb-50"
                  placeholder="Sable (mm)"
                  type="number"/>
              <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
            </validation-provider>
          </div>
        </tab-content>
        <tab-content title="Résultats">
          Votre sol à une texture :
          <div class="my-1 text-center font-weight-bold font-large-3"> {{ texture }}</div>
          <div v-if="!initialSetUp">
            Cliquez sur le(s) potager(s) avec ce type de sol pour modifier la/les caractéristique(s) et améliorer la
            qualité de nos conseils :
            <div v-for="(vGarden, index) in vGardens" :key="index" class="w-full flex justify-center m-1">
              <b-button
                  v-if="vGarden.soil_type !== result"
                  variant="secondary"
                  @click="setSoil(vGarden)">
                {{ vGarden.name }}
              </b-button>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </validation-observer>

    <b-modal
        v-model="validateSoilChangePrompt"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Modifier le titre de la fiche pratique"
        @ok="updateVGardenSoil()">
      Etes vous sûr d'ajouter {{ texture }} comme texture du sol du potager
      "{{ editingVGarden ? editingVGarden.name : '' }}" ?
    </b-modal>

  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import JayaTitle from '@/components/global/JayaTitle.vue'
import {BButton, BFormInput, BFormText, BModal} from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {min_value} from "vee-validate/dist/rules";

extend('min_value', {...min_value, message: "L'épaisseur ne peut pas être négative"});

export default {
  name: "soil-tests",
  components: {
    BModal,
    BFormInput,
    BButton,
    FormWizard,
    TabContent,
    BFormText,
    JayaTitle,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    initialSetUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clay: '',
      silt: '',
      sand: '',
      result: '',
      texture: '',
      validateSoilChangePrompt: false,
      editingVGarden: null,
    }
  },
  computed: {
    vGardens() {
      return this.$store.state.garden.vgardens
    }
  },
  methods: {
    getResult() {
      //make sure numbers are entered
      if ((!isNaN(this.clay) && this.clay !== '') && (!isNaN(this.silt) && this.silt !== '') && (!isNaN(this.sand) && this.sand !== '')) {
        //get percentages of each catégory using GEPPA 1963 texture diagram
        let totalHeight = Number(this.clay) + Number(this.silt) + Number(this.sand)
        let clayPercent = this.clay / totalHeight
        let siltPercent = this.silt / totalHeight
        if (clayPercent >= 0.3) {
          this.texture = 'Argile'
          this.result = 'A'
        } else if (siltPercent >= 0.5) {
          this.texture = 'Limon'
          this.result = 'L'
        } else if (clayPercent <= 0.2 && siltPercent <= 0.25) {
          this.texture = 'Sableux'
          this.result = 'S'
        } else if (clayPercent > 0.2 && siltPercent > 0.25) {
          this.texture = 'Limon argilo-sableux'
          this.result = 'LAS'
        } else if (clayPercent <= 0.2) {
          this.texture = 'Argilo-sableux'
          this.result = 'AS'
        } else {
          this.texture = 'Limon sableux'
          this.result = 'LS'
        }
        return true
      } else {
        this.$notify({
          time: 4000,
          title: 'Erreur',
          color: 'danger',
          text: "Les trois valeurs doivent être des nombres"
        })
        return false
      }
    },
    setSoil(vGarden) {
      this.editingVGarden = vGarden
      this.validateSoilChangePrompt = true
    },
    updateVGardenSoil() {
      if (this.editingVGarden) {
        let payload = {
          soil_type: this.result,
          id: this.editingVGarden.id,
        }
        this.$store.dispatch('garden/updateVGarden', payload)
            .then(() => {
              this.$notify({
                time: 4000,
                title: 'Réussi',
                color: 'primary',
                text: "Le potager " + this.editingVGarden.name + " a été mise à jour"
              })
              this.editingVGarden = null
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: 'Echec',
                color: 'danger',
                text: "Un problème est survenu, veuillez réessayer"
              })
              this.editingVGarden = null
            })
      }
    }
  },
}
</script>

<style lang="scss">
.soil-test {
  .vue-form-wizard .wizard-tab-content {
    padding-left: 0;
    padding-right: 0;
  }

  .wizard-header {
    padding: 0;
  }
}
</style>
