<template>
  <div class="soil-identification max-w-xl">
    <JayaTitle classType="page-title" noFilter titleName="Identifier son sol"/>
    <p class="mt-1">
      Pour vous aider à identifier la texture
      de votre sol, nous vous proposons la méthode ci-dessous.
    </p>
    <!-- Evaluations par tests -->
    <b-card
        :border-variant="soilTest ? 'secondary' : 'primary'"
        :header-bg-variant="soilTest ? 'secondary' : 'primary'"
        body-class="p-0"
        class="relative my-3 max-w-md"
        header-text-variant="white">
      <template #header>
        <div class="font-medium-3">
          {{ soilTest ? 'Test du bocal' : 'Qualifier son sol par le test du bocal' }}
        </div>
        <b-button
            v-if="soilTest"
            class="p-0"
            @click="soilTest = false">
          <feather-icon
              class="cursor-pointer"
              icon="RotateCcwIcon"
              size="21"/>
        </b-button>
      </template>
      <b-card-body>
        <div v-if="!soilTest">
          <div class="font-small-3">
            <p>
              Une façon efficace d'estimer la composition de son sol en mesurant
              la proportion des différentes particules qui le composent.
            </p>
            <p><strong>Matériel requis: </strong>Un bocal transparent, un échantillon de votre terre et de l'eau </p>
            <p><strong>Temps requis: </strong> Préparation cinq minutes, résultats 24 heures </p>
          </div>
          <div class="d-flex justify-content-center mt-1">
            <b-button
                variant="primary"
                @click="soilTest = true">
              Faire le test
            </b-button>
          </div>
        </div>
        <div v-else>
          <SoilTests ref="SoilTests" :initialSetUp="initialSetUp" @testDone="soilTest = false"/>
        </div>
      </b-card-body>
    </b-card>

    <div class="mt-5">
      <h3 class="text-primary">Pourquoi identifier la texture de son sol ?</h3>
      <p class="mt-1">
        La connaissance de la texture permet d'indiquer les tendances du sol quant à ses qualités physiques.
        La texture a une influence primordiale sur le régime hydrique des sols.
        Il influence la réserve utile dont une plante dispose, ainsi que la circulation de l'eau dans le sol.
      </p>
      <p class="mt-1">
        La texture du sol influence aussi l'aération du sol et par
        conséquent la facilité de pénétration des racines.
      </p>
      <p class="mt-1">
        Une granulométrie considérée comme favorable à la culture est composée de :
        <br> - 15 à 25 % d'argile,
        <br> - 30 à 35 % de limons,
        <br> - 40 à 50 % de sables.
      </p>
    </div>
  </div>
</template>

<script>
import {BButton, BCard, BCardBody} from "bootstrap-vue";
import JayaTitle from '@/components/global/JayaTitle.vue'
import SoilTests from '@/components/vgarden/evaluations/SoilTests.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    JayaTitle,
    SoilTests,
  },
  props: {
    initialSetUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      soilTest: false
    }
  }
}
</script>

<style lang="scss">
.soil-identification {
  p {
    text-align: justify;
  }
}
</style>

