<template>
  <div class="vgarden-tasks max-w-md">
    <b-card v-if="this.getPlantTasks.sowing.length > 0" no-body class="p-50 mb-1">
      <TextBlock title="Semer">
        <img slot="titleSpace" alt="Icon semer" class="icon" src="@/assets/images/icons/other/sowing_hand.svg">
        <span v-for="plant in this.getPlantTasks.sowing" :key="plant.id">
          <TaskObject :name="plant.common_name" :plantId="plant.id" :vGarden="vGarden" text="J'ai semé" :state="{state:'PA'}" @taskValidated="taskValidated">
          </TaskObject>
        </span>
      </TextBlock>
    </b-card>

    <b-card v-if="this.getPlantTasks.planting.length > 0" no-body class="p-50 mb-1">
      <TextBlock  title="Repiquer en terre">
        <img slot="titleSpace" alt="Icon transplantoire" class="icon" src="@/assets/images/icons/tools/garden_trowel_dark.svg">
        <span v-for="plant in this.getPlantTasks.planting" :key="plant.id">
          <TaskObject :name="plant.common_name" :plantId="plant.id" :vGarden="vGarden" text="J'ai repiqué" :state="{state:'SO'}" @taskValidated="taskValidated">
          </TaskObject>
        </span>
      </TextBlock>
    </b-card>

    <b-card v-if="this.getPlantTasks.harvesting.length > 0" no-body class="p-50 mb-1">
      <TextBlock title="Récolter">
        <img slot="titleSpace" alt="Icon panier" class="icon" src="@/assets/images/icons/tools/basket.svg">
        <span v-for="plant in this.getPlantTasks.harvesting" :key="plant.id">
          <TaskObject :name="plant.common_name" :plantId="plant.id" :vGarden="vGarden" text="J'ai récolté" :state="{state:'PL'}" @harvest="addHarvest">
          </TaskObject>
        </span>
      </TextBlock>
    </b-card>

    <b-card v-if="this.vGarden.tasks.filter(task => task.object !== 'plant').length > 0" no-body class="p-50">
      <TextBlock title="Organisation du potager">
      </TextBlock>
    </b-card>

    <b-card no-body>
      <b-card-title class="p-1 m-0">
        Déclarer un changement
      </b-card-title>
      <div class="d-flex flex-column align-items-center">
        <b-button class="mb-50" variant="outline-primary" @click="fertilizePrompt=true">
          J'ai ajouté de l'engrais
        </b-button>
        <b-button class="mb-50" variant="outline-primary" @click="mulchPrompt=true">
          J'ai paillé
        </b-button>
      </div>
    </b-card>

    <div class="text-info cursor-pointer font-weight-bold d-flex justify-content-center mb-5 mx-50" @click="taskHelpPopUp=true">
      <feather-icon
          class="mr-50"
          icon="HelpCircleIcon"
          size="16"/>
      En savoir plus sur les actions
    </div>

    <b-modal
        ref="fertPrompt"
        v-model="fertilizePrompt"
        :state="fertilizationType !== ''"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Vous avez fertilisé votre potager ?"
        @cancel="resetDate"
        @close="resetDate"
        @ok="updateFertilization">

      <p v-if="getLastFert" class="text-info text-center">
        Dernière fertilisation réalisée le :
        <strong> {{ getLastFert }}</strong>
        <jaya-divider/>
      </p>


      <b-form-group label="Quand avez vous fertilisé ? ">
        <flat-pickr v-model="dateDone" :config="configDateTimePicker" class="form-control"/>
      </b-form-group>

      <b-form-group label="Vous avez fertilisé avec : ">
        <b-form-select
            v-model="fertilizationType"
            :options="$store.state.garden.fertilizationOptions"/>
      </b-form-group>
    </b-modal>

    <b-modal
        ref="mulchPrompt"
        v-model="mulchPrompt"
        :state="mulchType !== ''"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Paillage"
        @ok="updateMulch"
        @cancel="resetDate"
        @close="resetDate">

      <p v-if="getLastMulch" class="text-center text-info">
        Dernier paillage réalisé le :
        <strong> {{ getLastMulch }}</strong>
        <jaya-divider/>
      </p>

      <b-form-group label="Quand avez vous paillé ?">
        <flat-pickr v-model="dateDone" :config="configDateTimePicker" class="form-control"/>
      </b-form-group>

      <b-form-group label="Vous avez paillé avec : ">
        <b-form-select
            v-model="mulchType"
            :options="$store.state.garden.mulchOptions"/>
      </b-form-group>
    </b-modal>

    <b-modal
        v-model="taskHelpPopUp"
        centered
        class="task-help"
        hide-footer
        title="Système d'actions Hortilio">
      <h5>Fonctionnement du système d'actuions</h5>
      <p class="text-justify">
        Dans cet onglet, retrouvez l'ensemble des actions à réaliser dans votre potager. Ces actions étant regroupées pour
        l'ensemble des plants d'une variété, rendez vous dans l'onglet « Emplacements » pour modifier une plante en
        particulier.
      </p>
      <p class="text-justify">
        Intéragir avec les actions permet au système de situer la plante dans son cycle de vie et donc de vous proposer
        des rappels sur ce qu'il vous reste à effectuer au jardin.
      </p>
      <h6 class="mt-2 mb-1 text-dark">Les récoltes</h6>
      <p class="text-justify">
        Une fois en terre, et lorsque la période s'y prête, une plante peut être récoltée. Il vous est alors proposé
        d'indiquer les quantités récoltées et d'accéder ainsi à un suivi de votre production.
      </p>
      <h5>Pourquoi utiliser ce système ?</h5>
      <p class="text-justify">
        Indiquer les différentes actions que vous effectuez au potager nous permet de créer un historique du potager.
        Sur la base de cet historique, nous serons en mesure vous proposer des conseils chaque année plus personnalisés
        et adaptés au climat de votre région.
        Cet historique vous sera également accessible dès l'année prochaine afin de visualiser l'évolution de votre
        pratique au fil du temps.
      </p>
      <p class="text-justify">
        Afin de mutualiser l'expérience collective et toujours dans l'objectif d'améliorer les connaissances et conseils
        que nous vous proposons, nous ferons également continuellement le pont avec des organismes de recherche en
        agronomie.
      </p>
    </b-modal>
  </div>
</template>

<script>
import TextBlock from '@/components/global/JayaTextBlock.vue'
import TaskObject from '@/components/vgarden/tasks/VGardenTaskObject.vue'
import PlantTask from '@/components/vgarden/tasks/VGardenPlantTasks.vue'
import FlatPickr from 'vue-flatpickr-component'
import {BButton, BFormGroup, BFormSelect, BModal, BCard, BCardTitle} from "bootstrap-vue";

export default {
  name: 'VGardenTasks',
  components: {
    TextBlock,
    TaskObject,
    PlantTask,
    FlatPickr,
    BModal,
    BButton,
    BFormSelect,
    BFormGroup,
    BCard,
    BCardTitle,
  },
  props: {
    vgardenId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      sickPlantPrompt: false,
      sickPlant: -1,
      sickness: '',
      plantSearch: false,
      weedPrompt: false,
      mulchPrompt: false,
      fertilizePrompt: false,
      weedType: '',
      fertilizationType: null,
      mulchType: null,
      weedQuantity: '',
      otherFeedbackPrompt: false,
      otherFeedback: '',
      configDateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      dateDone: null,
      taskHelpPopUp: false,
    }
  },
  mounted() {
    let today = new Date()
    this.dateDone = today
  },
  beforeDestroy() {
    this.$refs.mulchPrompt.$el.parentNode.removeChild(this.$refs.mulchPrompt.$el)
    this.$refs.mulchPrompt.$destroy()
    this.$refs.fertPrompt.$el.parentNode.removeChild(this.$refs.fertPrompt.$el)
    this.$refs.fertPrompt.$destroy()
  },
  computed: {
    vGarden() {
      return this.$store.state.garden.vgardens.find(vGarden => this.vgardenId === vGarden.id)
    },
    getPlantTasks() {
      let vGarden = this.$store.state.garden.vgardens.find(vGarden => this.vgardenId === vGarden.id)
      let sowingPlants = []
      let plantingPlants = []
      let trimingPlants = []
      let harvestingPlants = []
      for (const vGargenTask of vGarden.tasks) {
        if (vGargenTask && vGargenTask.object === 'plant') {
          if (vGargenTask.tasks.sow) {
            sowingPlants.push(vGargenTask.plantType)
          }
          if (vGargenTask.tasks.transplant) {
            plantingPlants.push(vGargenTask.plantType)
          }
          if (vGargenTask.tasks.trim) {
            trimingPlants.push(vGargenTask.plantType)
          }
          if (vGargenTask.tasks.harvest) {
            harvestingPlants.push(vGargenTask.plantType)
          }
        }
      }
      return {
        sowing: sowingPlants,
        planting: plantingPlants,
        triming: trimingPlants,
        harvesting: harvestingPlants,
      }
    },
    getLastMulch() {
      let mulchs = [...this.vGarden.mulchings]
      if (mulchs.length) {
        let lastMulch = mulchs.sort((a, b) => b.date - a.date)[mulchs.length - 1]
        let date = new Date(lastMulch.date)
        let month = Number(date.getMonth()) + 1
        let dateFrench = date.getDate() + '/' + month.toString() + '/' + date.getFullYear()
        return dateFrench
      }
      return null
    },
    getLastFert() {
      let ferts = [...this.vGarden.fertilizations]
      if (ferts.length) {
        let lastFert = ferts.sort((a, b) => b.date - a.date)[ferts.length - 1]
        let date = new Date(lastFert.date)
        let month = Number(date.getMonth()) + 1
        let dateFrench = date.getDate() + '/' + month.toString() + '/' + date.getFullYear()
        return dateFrench
      }
      return null
    }
  },
  methods: {
    getDateJSON(eventDate) {
      let date =  eventDate ? new Date(eventDate) : new Date(new Date().setUTCHours(0,0,0,0))
      return date.toISOString()
    },
    taskValidated(e) {
      let plants = this.vGarden.vgarden_plants.filter(pl => {
        return pl.garden_plant.plant.id === e.plantId && (pl.current_state.state === e.type || (pl.current_state.state === 'AC' && e.info === 'PL'))
      })
      let callsToDo = plants.length
      let callsDone = 0
      for (let plant of plants) {
        let payload = {
          state: e.info,
          v_garden_plant: plant.id,
          date: this.getDateJSON(e.date)
        }
        this.$store.dispatch('garden/createVGPlantState', payload)
            .then(() => {
              callsDone += 1
              if (callsDone === callsToDo) {
                this.$emit('update-vgarden')
                this.$notify({
                  time: 4000,
                  title: 'Plante(s) mise(s) à jour',
                  text: 'Vos plante(s) ont bien été mise(s) à jour',
                  color: "primary"
                })
              }
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                color: "danger",
                title: "Erreur",
                text: "Une erreur est survenue lors de la prise en compte de l'état de la plante, veuillez réessayer plus tard"
              })
            })
      }
    },
    addHarvest(e) {
      //if harvest use feedback info
      if (e.info === 'HA' && e.feedBack && e.feedBack.type) {
        let plants = this.vGarden.vgarden_plants.filter(pl => {
          return pl.garden_plant.plant.id === e.plantId && pl.current_state.state === e.type && e.feedBack.plantResults.quantity[pl.id]
        })
        for (let plant of plants) {
          let payload = {
            vegetable_garden: this.vGarden.id,
            v_garden_plant: plant.id,
            index_store: this.vGarden.vgarden_plants.findIndex(vGPlant => vGPlant.id === plant.id),
            state: e.state,
            date: this.getDateJSON(e.date)
          }
          switch (e.feedBack.plantResults.quality[plant.id]) {
            case -2:
              payload.quality = 1
              break;
            case -1:
              payload.quality = 3
              break;
            case 0:
              payload.quality = 5
              break;
            case 1:
              payload.quality = 7
              break;
            case 2:
              payload.quality = 10
              break;
            default:
              payload.quality = null
              break;
          }

          if (e.feedBack.type === 'quantity') {
            payload.number = e.feedBack.plantResults.quantity[plant.id]
          } else {
            payload.mass = e.feedBack.plantResults.quantity[plant.id]
          }
          this.$store.dispatch("garden/createHarvest", payload)
              .then(() => {
                this.$notify({
                  time: 4000,
                  color: "primary",
                  title: "Récolte",
                  text: "Récolte bien prise en compte"
                })
              })
              .catch(() => {
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Erreur",
                  text: "Une erreur est survenue lors de la prise en compte de la récolte, veuillez réessayer plus tard"
                })
              })
        }
      } else {
        this.$notify({
          time: 4000,
          color: "danger",
          title: "Erreur",
          text: "Une erreur est survenue lors de la prise en compte de la récolte, veuillez réessayer plus tard"
        })
      }
    },
    updateSickness() {
      this.$notify({
        time: 4000,
        title: 'En dev',
        color: 'danger',
        text: 'A bientôt'
      })
    },
    updateFertilization() {
      let payload = {
        composition: this.fertilizationType,
        date: this.getDateJSON(this.dateDone),
        vegetable_garden: this.vgardenId
      }
      this.$store.dispatch('garden/createFertilization', payload)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Engrais",
              text: "La fertilisation de votre potager a bien été prise en compte"
            })
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue lors de la prise en compte de la fertilisation, veuillez réessayer plus tard"
            })
          })
    },
    updateMulch() {
      let payload = {
        composition: this.mulchType,
        date: this.getDateJSON(this.dateDone),
        vegetable_garden: this.vgardenId
      }
      this.$store.dispatch('garden/createMulching', payload)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Paillage",
              text: "Le paillage de votre potager a bien été pris en compte"
            })
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue lors de la prise en compte du paillage, veuillez réessayer plus tard"
            })
          })
    },
    resetDate() {
      this.dateDone = new Date()
    },
  },
}
</script>

<style lang="scss">
.vgarden-tasks {
  .icon {
    height: 30px;
    padding-left: 5px;
  }
}

.task-help {
  text-align: justify;

  p {;
    margin-bottom: 0.5rem;
  }

  h5 {
    color: rgba(var(--vs-primary), 1);
    margin-bottom: 0.5rem;
  }
}
</style>

