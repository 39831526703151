<template>
  <div class="v-garden">
    <div class="d-flex align-items-center justify-content-center">
      <JayaTitle :titleName="vGarden.name" class="m-0" noFilter/>
      <b-button
          class="mb-25 p-50"
          variant="gray"
          @click="$refs.popUpVGarden.openPopUp()">
        <feather-icon icon="SettingsIcon" size="18"/>
      </b-button>
      <b-button
          class="mb-25 p-25"
          variant="gray"
          @click="$refs.newNote.show()">
        <feather-icon icon="PenToolIcon" size="18"/>
      </b-button>
    </div>

    <b-tabs ref="vgardenTabs" fill>
      <b-tab title="Actions" @click="toSlide(0)"/>
      <b-tab title="Emplacements" @click="toSlide(1)"/>
      <b-tab title="Plantes" @click="toSlide(2)"/>
    </b-tabs>

    <swiper
        id="swiper"
        ref="contentSwiper"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="swiperOption"
        class="swiper-box"
        @init="setIndex()"
        @slide-change="changeTab()">
      <swiper-slide class="swiper-item" data-hash="tasks">
        <VGardenTasks :vgardenId="Number($route.params.vgardenId)" @update-vgarden="getVGardenUpdate"/>
      </swiper-slide>
      <swiper-slide class="swiper-item" data-hash="location">
        <VGardenLocations
            v-if="Object.keys(vGarden).length && $route.hash.includes('#location')"
            :key="locationsKey"
            :vgarden="vGarden"
            @update-vgarden="getVGardenUpdate"/>
      </swiper-slide>
      <swiper-slide class="swiper-item mb-32" data-hash="list">
        <div v-if="$route.hash.includes('#list') || $route.hash === ''" :key="plantListKey" class="max-w-md pb-5 mb-5">
          <div class="d-flex justify-content-center mb-50">
            <b-button
                variant="primary"
                @click="$refs.interactionsPopUp.openPopUp()">
              <feather-icon class="mr-50" icon="RefreshCwIcon" size="14"/>
              <span class="align-middle">Voir les cultures associées</span>
            </b-button>
          </div>

          <jaya-divider v-if="orderedVGardenPlants(true, true).length > 0" color="primary" text="A faire en ce moment"/>

          <PlantCard
              v-for="plant in orderedVGardenPlants(true, true)"
              :key="plant.id"
              :gardenPlantId="plant.garden_plant.id"
              :plant-pictos="plant.garden_plant.plant.pictos"
              :plantId="plant.garden_plant.plant.id"
              :plantNotifications="plantTasks(plant)"
              :plantPhotos="plant.garden_plant.plant.photos"
              :quantity="plant.quantity_state"
              :received_interactions="plant.garden_plant.plant.received_interactions"
              :subtitle="plant.garden_plant.plant.scientific_name"
              :title="plant.garden_plant.plant.common_name"
              :vGarden="vGarden"
              :vGardenPlantId="plant.id"
              @plant-update="getVGardenUpdate"/>

          <jaya-divider v-if="orderedVGardenPlants(true, true).length > 0" color="primary"/>

          <PlantCard
              v-for="(plant, index) in orderedVGardenPlants(true, false)"
              :key="index"
              :gardenPlantId="plant.garden_plant.id"
              :plant-pictos="plant.garden_plant.plant.pictos"
              :plantId="plant.garden_plant.plant.id"
              :plantPhotos="plant.garden_plant.plant.photos"
              :quantity="plant.quantity_state"
              :received_interactions="plant.garden_plant.plant.received_interactions"
              :subtitle="plant.garden_plant.plant.scientific_name"
              :title="plant.garden_plant.plant.common_name"
              :vGarden="vGarden"
              :vGardenPlantId="plant.id"
              @plant-update="getVGardenUpdate"/>
        </div>
      </swiper-slide>
    </swiper>

    <div v-if="$route.hash.includes('#list')" class="fixed-bottom">
      <div class="d-flex center flex-wrap justify-content-around mb-1 max-w-sm">
        <b-button class="mb-25" variant="dark" @click="getPlantAdvice">
          <feather-icon class="mr-25" icon="ArrowRightIcon" size="14"/>
          <span class="align-middle"> Conseils d'associations </span>
        </b-button>
        <b-button variant="primary" @click="searchPlant">
          <feather-icon class="mr-25" icon="PlusIcon" size="14"/>
          <span class="align-middle"> Ajouter une plante </span>
        </b-button>
      </div>
    </div>

    <JayaPopUp ref="popUpVGarden" addPadding name="informations">
      <div class="w-100 d-block">
        <div class="w-100 d-flex justify-content-center">
          <feather-icon class="text-center" icon="SettingsIcon" size="24"/>
        </div>
        <VGardenInfo @updateInfo="getVGardenUpdate" @endUpdate="updateAndCloseInfo"/>
      </div>
    </JayaPopUp>

    <JayaPopUp
        v-if="Object.keys(vGarden).length"
        ref="addPlantPopUp"
        name="ajout de plante"
        title="Ajouter au potager ?"
        @scroll-bottom="$refs.plantSearch.loadNext()">
      <PlantSearch
          v-if="!getAdvice"
          ref="plantSearch"
          :activeFilter="true"
          :vGarden="vGarden"
          clickToGiveId
          @newSelection="addPlant"/>
      <div v-else>
        <JayaTitle class="mb-3" classType="page-title" titleName="Associations positives"/>
        <p class="w-full text-center mb-1"> Les plantes qui s'associent le mieux au sein de votre potager </p>
        <PlantCard
            v-for="plant in plantAdviceList"
            :key="plant.id"
            :plant-photos="plant.photos"
            :plant-pictos="plant.pictos"
            :plantId="plant.id"
            :subtitle="plant.scientific_name"
            :title="plant.common_name"
            clickToGiveId
            v-on:cardClicked="addPlant(plant)"/>
      </div>
      <VGPlantAdd ref="addPlantPrompt" :addingPlant="addingPlant" :vGarden="vGarden" @plant-added="onPlantAdded()"/>
    </JayaPopUp>

    <JayaPopUp ref="interactionsPopUp" name="interactions" title="Interactions">
      <div>
        <JayaTitle classType="page-title" titleName="Cultures associées"/>
        <VGardenInteractions :plantList="orderedVGardenPlants(false)" :vGarden="vGarden"/>
      </div>
    </JayaPopUp>

    <b-modal
        ref="newNote"
        hide-footer
        scrollable
        centered
        size="lg"
        title="Nouvelle note">
      <NoteEdit new :linkedObject="vGarden"/>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BModal, BTab, BTabs} from "bootstrap-vue";
import JayaTitle from '@/components/global/JayaTitle.vue'
import PlantCard from '@/components/plants/PlantCard.vue'
import JayaPopUp from '@/components/global/JayaPopUp.vue'
import VGardenTasks from '@/components/vgarden/tasks/VGardenTasks.vue'
import VGardenInfo from '@/components/vgarden/VGardenInfo.vue'
import VGardenLocations from '@/components/vgarden/VGardenLocations.vue'
import _ from 'lodash'
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
import PlantSearch from "@/components/plants/PlantSearch.vue"
import VGardenInteractions from '@/components/vgarden/VGardenInteractions.vue'
import NoteEdit from '@/components/notes/NoteEdit.vue'
import VGPlantAdd from "@/components/plants/VGPlantAdd";

export default {
  components: {
    BButton,
    BModal,
    BTabs,
    BTab,
    Swiper,
    SwiperSlide,
    JayaTitle,
    PlantCard,
    JayaPopUp,
    VGardenTasks,
    VGardenInfo,
    VGardenLocations,
    PlantSearch,
    VGardenInteractions,
    NoteEdit,
    VGPlantAdd,
  },
  data() {
    return {
      vGarden: {},
      popUpNewSeason: false,
      addPlantNumber: 1,
      addPlantType: 'S',
      addPlantAreaLength: null,
      addPlantAreaWidth: null,
      addPlantAreaSpacingX: null,
      addPlantAreaSpacingY: null,
      getAdvice: false,
      plantAdviceList: [],
      locationsKey: 0,
      plantListKey: 0,
      pages: {
        tasks: 0,
        location: 1,
        list: 2
      },
      openPage: 0,
      swiperOption: {
        allowTouchMove: true,
        hashNavigation: {
          watchState: true,
        },
        breakpoints: {
          768: {
            allowTouchMove: false,
          }
        }
      },
      addingPlant: {},
      stateParser: {'SO': 'transplant', 'PA': 'sow', 'AC': 'transplant', 'PL': 'harvest'},
    }
  },
  beforeMount() {
    this.openPage = this.pages[this.$route.hash.split('#')[1]]
    this.getVGardenUpdate()
  },
  methods: {
    getVGardenUpdate() {
      let vgardenId = Number(this.$route.params.vgardenId)
      this.$loading(true)
      this.$store.dispatch("garden/getVGarden", {index: vgardenId})
          .then(response => {
            this.$store.commit('garden/UPDATE_VGARDEN', response.data)
            this.vGarden = response.data
            this.updateVGardenInfo()
            this.$loading(false)
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue au chargement du potager"
            })
            this.$router.push({name: 'jayaccueil'})
            this.$loading(false)
          })
    },
    async updateAndCloseInfo() {
      await this.getVGardenUpdate()
      this.$refs.popUpVGarden.closePopUp()
    },
    changeTab() {
      this.$refs.vgardenTabs.leftx = this.$refs.contentSwiper.$swiper.activeIndex * this.$refs.vgardenTabs.widthx
      this.$refs.vgardenTabs.currentTab = this.$refs.contentSwiper.$swiper.activeIndex
    },
    toSlide(i) {
      this.$refs.contentSwiper.$swiper.slideTo(i, 0)
    },
    searchPlant() {
      this.getAdvice = false
      this.$refs.addPlantPopUp.openPopUp()
    },
    getPlantAdvice() {
      this.getAdvice = true
      let plantIdList = []
      for (const plant of this.vGarden.vgarden_plants) {
        plantIdList.push(plant.garden_plant.plant.id)
      }
      this.$store.dispatch("plant/searchAdvice", {
        limit: 20,
        plant_ids: plantIdList,
        specy_only: true
      })
          .then((response) => {
            this.plantAdviceList = response.data.results
          })
          .catch(() => {
            this.plantAdviceList = []
          })
      this.$refs.addPlantPopUp.openPopUp()
    },
    addPlant(value) {
      this.$store.dispatch("plant/get", {id: value.id})
          .then((response) => {
            this.addingPlant = response.data
            this.$refs.addPlantPrompt.addPlantPrompt = true
          })
    },
    onPlantAdded() {
      this.getVGardenUpdate()
      this.$refs.addPlantPrompt.addPlantPrompt = false
      this.$refs.addPlantPopUp.closePopUp()
      this.plantListKey = this.plantListKey + 1
      this.$refs.contentSwiper.$swiper.slideTo(1, 0)
    },
    plantTasks(vGPlant) {
      if (this.vGarden.tasks && this.vGarden.tasks.some(task => {
        return task.object === 'plant' && task.plantType.id === vGPlant.garden_plant.plant.id && task.tasks[this.stateParser[vGPlant.current_state.state]]
      })) {
        return this.vGarden.tasks.find(task => {
          return task.object === 'plant' && task.plantType.id === vGPlant.garden_plant.plant.id && task.tasks[this.stateParser[vGPlant.current_state.state]]
        }).tasks
      }
      return []
    },
    isActiveTask(tasks) {
      return (tasks.sow
          || tasks.trim
          || tasks.harvest
          || tasks.transplant)
    },
    updateVGardenInfo() {
      this.popUpVGarden = false
      this.locationsKey = this.locationsKey + 1
    },
    orderedVGardenPlants(includeState, getActive) {
      let resultArray = []
      if (this.vGarden.vgarden_plants) {
        for (const plantType of this.vGarden.vgarden_plants) {
          const func = (item) => {
            let bool = item.garden_plant.plant.id === plantType.garden_plant.plant.id
            if (includeState) {
              bool = bool && (item.current_state.state === plantType.current_state.state)
            }
            return bool
          }
          // Get plants by id and state
          if (!resultArray.some(func)) {
            if (includeState) {
              plantType.quantity_state = 0
              this.vGarden.vgarden_plants.filter(func).forEach((plant) => {
                plantType.quantity_state += plant.item_number
              })
            } else {
              plantType.quantity = 0
              this.vGarden.vgarden_plants.filter(func).forEach((plant) => {
                plantType.quantity += plant.item_number
              })
            }
            resultArray.push(plantType)
          }
        }
      }

      if (!includeState) {
        return _.orderBy(resultArray, 'garden_plant.plant.common_name')
      } else {
        if (getActive) {
          return _.orderBy(resultArray.filter(item => this.isActiveTask(this.plantTasks(item))), 'garden_plant.plant.common_name')
        } else {
          return _.orderBy(resultArray.filter(item => !this.isActiveTask(this.plantTasks(item))), 'garden_plant.plant.common_name')
        }
      }
    },
  },
  watch: {
    '$route.hash'(val) {
      if (val.includes('#tasks') || val === "") {
        this.toSlide(0)
        this.openPage = 0
      }
      if (val.includes('#location')) {
        this.toSlide(1)
        this.openPage = 1
      }
      if (val.includes('#list')) {
        this.toSlide(2)
        this.openPage = 2
      }
    }
  }
}
</script>

<style lang="scss">
.router-view {
  padding-bottom: 0 !important;
}

.v-garden {
  width: 100%;

  .interactions {
    padding: 0 !important;
  }

  .swiper-item {
    margin-top: 10px;
    overflow: auto;
  }
}
</style>
