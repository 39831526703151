<template>
  <div class="vgarden-design-help">
    <p>
      Hortilio vous propose un outil de conception de votre potager.
      Il vous aidera à augmenter sa résilience. Pour se servir au mieux de l’outil, nous vous conseillons de suivre ces étapes :
    </p>
    <ul class="ml-25 mt-50">
      <li class="pb-1">
        1. Renseignez les informations de votre potager : dimensions, situation, ensoleillement, type de sol...
      </li>
      <li class="pb-1">
        2. S'il y en a, sélectionnez les plantes déjà en place chez vous dans votre potager, et ajoutez y celles que vous souhaitez commencer à cultiver
      </li>
      <li class="pb-1">
        3. Inspirez-vous des conseils Hortilio - basés sur le compagnonage végétal - pour ajouter des plantes bénéfiques pour votre potager
      </li>
      <li class="pb-1">
        4. Dans l'espace "Emplacements", déplacez puis fixez les plantes déjà en place dans votre potager.
        Pour cela, cliquez sur une plante - une nouvelle zone apparait en dessous du schéma du potager - et sélectionnez "Fixer".
      </li>
      <li class="pb-1">
        5a. Vous pouvez ensuite utiliser l'outil d'optimisation du placement.
        Celui-ci va automatiquement positionner les plantes non fixées afin de maximiser l'effet des associations positives.
        Ainsi, les plantes "amies" seront plutôt proche entre elles, et éloignées des plantes "ennemies".
      </li>
      <li class="pb-1">
        5b. C'est un version bêta, si l'optimisation échoue, vous pouvez la relancer. Vous noterez qu'en lançant plusieurs fois l'optimisation du placement, il est possible d'avoir des résultats différents.
        Pas d'inquiétude! Un bon positionnement n'est pas forcément unique...
      </li>
      <li class="pb-1">
        6. Si la position après l'optimisation ne vous convient pas, vous pouvez toujours changer les emplacements à la main,
      </li>
      <li class="pb-1">
        7. Validez le potager: ça y'est, vous avez terminé !
      </li>
    </ul>
    <div class="text-italic mt-50">
      Pour qu'Hortilio vous donne des conseils pertinents, faites en sorte d'être le plus proche de la réalité en renseignant ces informations.
    </div>
    <jaya-divider color="transparent"/>
  </div>
</template>

<script>
import TextBlock from '@/components/global/JayaTextBlock.vue'

export default {
  name: 'VGardenDesignHelp',
  components: {
    TextBlock
  }
}
</script>

<style lang="scss">
.vgarden-design-help {
  ul {
    list-style-type: none;
    padding-left: 10px;
  }
}
</style>