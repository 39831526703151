<template>
  <div class="soil-info">
    <div class="jaya-top-row">
      <div class="jaya-container">
        <img alt="Icon texture" class="jaya-image" src="@/assets/images/icons/other/soil.svg">
      </div>
    </div>
    <h1 class="text-center"> Le sol </h1>

    <TextBlock title="La sol et l'écosystème">
      <p>
        Outre le maintien physique, le sol apporte aux végétaux de l’eau, des éléments minéraux et organiques, qui sont
        essentiels à leurs vies.
        Il ne faut cependant pas considérer le sol comme un simple réservoir !
        Le sol abrite de nombreuses formes de vies et est un acteur important de l’écosystème, impactant toutes les
        autres.
      </p>

      <p>
        Idéalement il est souhaitable d’arriver à un sol équilibré dans son écosystème.
      </p>

      <p>
        Certaines actions vont jouer en faveur de cet équilibre tandis que certaines pratiques vont le pousser au
        déséquilibre.
      </p>

      <app-collapse>
        <app-collapse-item title="Voici nos conseils">
          <b-list-group class="incard" flush>
            <b-list-group-item>
              <strong class="text-primary">La polyculture</strong>
              (l’inverse de la monoculture), une diversité d’espèces tend à favoriser une biodiversité riche qui à son
              tour permet de rendre résilient l’équilibre des sols.
            </b-list-group-item>
            <b-list-group-item>
              <strong class="text-primary">Une intensité biologique et des systèmes complexes. </strong>
              Ceux-ci créent plus de niches écologiques qui favorisent la richesse de l’écosystème.
            </b-list-group-item>
            <b-list-group-item>
              <strong class="text-primary">Les arbres </strong>
              permettent des écosystèmes extrêmement riches, notamment grâce à la profondeur de leurs racines qui fixent
              le sol et au couvert qu’ils offrent à la biodiversité
            </b-list-group-item>
            <b-list-group-item>
              <strong class="text-primary">Les vers de terre, les champignons et autres organismes vivants,</strong>
              sont souvent signe d’un sol vivant et en bonne santé.
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
        <app-collapse-item title="A éviter">
          <b-list-group class="incard" flush>
            <b-list-group-item>
              <strong class="text-danger"> Retourner le sol, </strong>
              le labour, le bêchage ou encore les outils rotatifs ont à tendance à renverser l’équilibre qui s’installe
              en mélangeant les différentes couches du sol nuit aux formes de vies qui s’y installent.
              <span class="text-primary italic">Il est parfois quand même nécessaire de travailler le sol pour le cultiver, nous conseillons de réduire ces interventions au minimum.</span>
            </b-list-group-item>
            <b-list-group-item>
              <strong class="text-danger"> Un sol nu </strong>
              sera plus sensible aux éléments comme le soleil et le vent et perdra sa fertilité.
              <span class="text-primary italic">Nous vous conseillons de toujours couvrir vos sols, par un mulch ou par une végétation.</span>
            </b-list-group-item>
            <b-list-group-item>
              <strong class="text-danger"> Les produits de synthèse </strong>
              comme les engrais de synthèse, les herbicides, pesticides et fongicides, ont tendance à fortement
              déséquilibrer les sols, en nuisant à la vie.
              <span class="text-primary italic"> Les produits de synthèse existent pour répondre à des problèmes pour lesquels il existe très souvent d’autres solutions plus favorable pour la biodiversité. </span>
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
      </app-collapse>

      <p class="mb-1">
        Il est intéressant de connaître les différentes qualités du sol pour savoir quelles plantes s’adapteront mieux
        et quelles actions mener pour améliorer la santé générale du jardin.
        Dans ce but, nous décomposons ces différents qualités et paramètres en texture, matières organiques et acidité.
      </p>
    </TextBlock>

    <TextBlock title="Texture">
      <p>
        La connaissance de la texture permet d'indiquer les tendances du sol quant à ses qualités physiques.
        Elle est définie par les proportions relatives de particules argileuses, limoneuses et sableuses qui constituent
        la terre.
      </p>
      <p>
        La texture a une influence primordiale sur le régime hydrique (stockage et circulation de l’eau) des sols.
        Elle influence la réserve utile d'eau dont une plante dispose.
        Un sol sableux permet une meilleure aération, mais ne permet pas de stocker l’eau, puisque celle-ci circule plus
        facilement.
      </p>
      <p>
        La texture joue également un rôle important sur la richesse en éléments nurtitifs du sol ainsi que sur la
        stabilité structurale et la résistance à l’érosion.
      </p>
      <p>
        Les éléments plus gros que le sable (supérieur à 2mm de diamètre) gênent les potagers. Nous conseillons d’en
        enlever au maximum.
      </p>
      <p>
        Un sol considéré comme favorable à la culture est composé de
      </p>

      <b-list-group class="ml-4 incard" flush>
        <b-list-group-item>
          • 15 à 25 % d'argile,
        </b-list-group-item>
        <b-list-group-item>
          • 30 à 35 % de limon,
        </b-list-group-item>
        <b-list-group-item>
          • 40 à 50 % de sable.
        </b-list-group-item>
      </b-list-group>
      <b-button v-if="!noButtons" class="w-100" variant="flat-primary" @click="soilIdPopUp = true">
        Cliquez-ici pour evaluter la texture de votre sol
      </b-button>
    </TextBlock>

    <TextBlock title="Matières organiques">
      <p>
        Beaucoup de plantes maraichères (potagères) s’épanouissent dans des sols riches en matière organique.
        Avoir un jardin productif et résilient demande donc de s’assurer de l’apport en matière organique suffisamment
        sur ses potagers.
      </p>
      <p>
        Arriver à un équilibre d’un écosystème d’un jardin qui se maintient en terme de matière organique n’est pas une
        chose aisé.
        Cela peut mettre plusieurs années à s’installer et les changements de la vie peuvent le déséquilibrer.
        Il faut rester vigilent et continuellement être aux soins de notre environnement.
      </p>
      <p>
        Lors de la création d’un potager, il est utile de vérifier la qualité du sol, sa texture,
        son pH et sa richesse en matière organique et en nutriments, puis de corriger les défauts potentiels.
        Souvent il suffit d’y apporter de la matière organique, mais parfois il peut être utile d’y apporter des
        amendements minéraux aussi.
      </p>

      <p>
        Une fois avoir atteint un sol vivant dans le quel les cultures se portent bien,
        on peut réduire les apports et ne faire que compenser ce qui est utilisé par les cultures.
      </p>
      <p>
        Beaucoup de plantes maraichères (potagères) s’épanouissent dans des sols riches en matière organique.
        Avoir un jardin productif et résilient demande donc de s’assurer de l’apport en matière organique suffisant aux
        plantes, notamment au potager.
      </p>
      <p>
        On peut apporter des matières organiques en y ajoutant du compost, des engrais verts ou encore du fumier.
        Avec un sol déjà riche, un paillage peut suffire pour compenser la matière utilisé par les plantes.
      </p>
    </TextBlock>

    <TextBlock title="Acidité">
      <p>
        L’acidité d’un sol, mesurée par le pH, permet de connaitre la comptabilité avec ce sol.
      </p>
      <p>
        La majorité des plantes préfèrent un sol neutre (pH entre 6,5 et 7,2), mais certains végétaux ont besoin d’un
        sol acide comme les rhododendrons ou les hortensias par exemple.
      </p>
    </TextBlock>

    <b-modal
        v-model="soilIdPopUp"
        centered
        hide-footer
        size="lg">
      <SoilIdentification/>
    </b-modal>
  </div>
</template>

<script>
import TextBlock from '@/components/global/JayaTextBlock.vue'
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import SoilIdentification from '@/components/vgarden/evaluations/SoilIdentification.vue'
import {BButton, BListGroup, BListGroupItem, BModal} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BModal,
    TextBlock,
    AppCollapse,
    AppCollapseItem,
    SoilIdentification
  },
  props: {
    noButtons: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      soilIdPopUp: false
    }
  }
}
</script>

<style lang="scss">
.soil-info {
  p {
    margin-bottom: 0.75rem;
    text-align: justify;
  }
}
</style>
