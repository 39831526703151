<template>
  <div class="overflow-auto w-100 position-relative">

    <div class="d-flex justify-content-center swiper-no-swiping flex-column align-items-center">
      <div  class="mb-1 d-flex w-100 flex-row justify-content-center align-items-center" style="height: 30px !important;">
        <div class="mr-1 col-1"  v-if="activePlant">
          <b-button size="sm" variant="primary" class="btn-icon" disabled>
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </div>
        <div  v-if="activePlant" class="font-small-3 col-11">
          Mettre à jour les informations de {{activePlant.garden_plant.plant.common_name}}
        </div>
      </div>
      <VGardenCanvas
          :initialSetUp="initialSetUp"
          :vGarden="vgarden"
          :xLength="vGardenXLength"
          :yLength="vGardenYLength"
          @newActivePlant="newActivePlant"
          @plant-moved="$emit('plant-moved')"
          @openPlantSettings="openActivePlantSettings"/>
    </div>
    <b-modal v-model="promptActivePlant"
             v-if="activePlant"
             centered
             class="info-popup"
             hide-footer
             title="Détail des plants">
      <div v-if="!initialSetUp" class="mx-1">
        <PlantTasks :plantId="activePlant.garden_plant.plant.id" :vGardenPlantId="activePlant.id" specificPlant
                    @removed="transmitRemoved" @task-validated="transmitUpdate"/>
      </div>

      <div class="d-flex flex-column justify-content-center align-items-center">
        <p class="mb-50 font-weight-bold">{{ activePlant.fixed ? "La plante n'est pas déplaçable" : "Vous pouvez bouger la plante" }}</p>
        <b-button
            :variant="activePlant.fixed ? 'outline-primary' : 'outline-danger'"
            @click="changeFixed(activePlant)">
          {{ activePlant.fixed ? 'Détacher' : 'Fixer' }}
        </b-button>
      </div>
    </b-modal>

    <b-card
        class="max-w-xl my-1 ">
      <b-card-title>
        <h3>
          Outil de placement
          <b-button
              class="p-0 cursor-pointer"
              variant="flat-info"
              @click="designHelpPopUp = true">
            <feather-icon
                icon="HelpCircleIcon"
                size="24"/>
          </b-button>
        </h3>
      </b-card-title>
      <b-alert :show="vgarden.vgarden_plants.length <2" variant="danger" class="my-1">
        <div class="alert-body">
          <strong>Vous n'avez pas assez de plante dans votre jardin pour pouvoir utiliser l'outil ! </strong><br>
          Ajoutez au moins 2 plantes.
        </div>
      </b-alert>

      <div v-if="vgarden.vgarden_plants.length >1">
        <b-alert :show="plantsFill < 0.25" variant="warning" class="my-1">
          <div class="alert-body">
            <strong>Attention, vous avez peu de plantes par rapport à la taille de votre jardin ! </strong><br>
            En rapprochant les plantes compagnes et en
            éloignant les plantes ennemies, l'outil de placement peut créer des vides importants dans votre potager !
            Ajoutez d'autres plantes à partir de l'onglet "Plantes".
          </div>
        </b-alert>

        <b-alert :show="plantsFill > 1" variant="warning" class="my-1">
          <div class="alert-body">
            <strong>Attention, vous semblez avoir beaucoup de plantes par rapport à la taille de votre jardin ! </strong><br>
            Il se peut que l'outil de placement ne trouve pas de solution satisfaisante.
          </div>
        </b-alert>

        <b-alert show v-for="(message,id) in infoMessageOptim" :key="id" variant="warning" class="my-1">
          <div class="alert-body">
            {{ message }}
          </div>
        </b-alert>

        <div class="d-flex flex-column align-items-center">
          <b-button
              variant="primary" @click="optimizeCurrentPosition(false, 'manual')">
            Optimiser le placement
          </b-button>
        </div>
      </div>
    </b-card>

    <b-modal
        ref="designHelpPopUp"
        v-model="designHelpPopUp"
        class="help-popup"
        centered
        hide-footer
        title="La conception d'un potager">
      <VGardenDesignHelp/>
    </b-modal>

    <b-modal
        v-model="optimisePlacementPopup"
        centered
        class="help-popup"
        hide-footer
        title="Optimisation en cours">

      <b-alert show variant="warning" class="my-1 p-1">
        <div class="d-flex flex-column align-items-center">
          <b-spinner
              small
              variant="warning"
          ></b-spinner>
          <div class="alert-body text-center">
            Hortilio recherche le meilleur placement selon les associations bénéfiques et néfastes entre les plantes de votre potager...
            Cela durera {{optimMaxTime}} secondes au maximum !
          </div>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import PlantTasks from '@/components/vgarden/tasks/VGardenPlantTasks.vue'
import VGardenCanvas from '@/components/vgarden/VGardenCanvas'
import VGardenDesignHelp from '@/components/vgarden/VGardenDesignHelp.vue'
import {
  BButton,
  BModal,
  BCard,
  BAlert,
  BCardTitle,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "vgarden-locations",
  components: {
    PlantTasks,
    VGardenCanvas,
    VGardenDesignHelp,
    BModal,
    BButton,
    BCard,
    BAlert,
    BCardTitle,
    BSpinner,
  },
  props: {
    vgarden: {
      type: Object,
      required: true
    },
    initialSetUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      designHelpPopUp: false,
      optimisePlacementPopup: false,
      editLinePopUp: false,
      activePlant: null,
      promptActivePlant: false,

      //Orientation
      compassAngle: 0,
      orientation: false,
      touchX: 0,
      touchY: 0,

      //Opitmization
      nlopt: null,
      infoMessageOptim: null,
      optimMaxTime: 30,
    }
  },
  mounted() {
    if (this.initialSetUp && this.$store.state.garden.locationsHelpNeeded) {
      this.$store.commit('garden/UPDATE_LOCATIONS_HELP_NEEDED', false)
      this.designHelpPopUp = true
    }
    this.nlopt = require('nlopt-js')
  },
  computed: {
    sidePadding() {
      if (this.$store.state.app.windowWidth > 576) {
        return 61.6
      }
      return 33.6
    },
    vGardenXLength() {
      if (this.$store.state.app.windowWidth < 900) {
        return this.$store.state.app.windowWidth - this.sidePadding
      }
      return 900 - 61.6
    },
    vGardenYLength() {
      return (this.vGardenXLength * this.vgarden.width / this.vgarden.length)
    },
    plantsFill() {
      let areaPlants = 0
      for (const plant of this.vgarden.vgarden_plants) {
        areaPlants += 4 * Math.pow(plant.garden_plant.plant.jaya_infos.planting_radius, 2) * plant.item_number
      }
      return areaPlants / (this.vgarden.width * this.vgarden.length)
    },
  },
  methods: {
    newActivePlant(event) {
      this.activePlant = event
    },
    openActivePlantSettings() {
      if (this.activePlant) {
        this.promptActivePlant = true
      }
    },
    changeFixed(plant) {
      let plantIndex = this.vgarden.vgarden_plants.findIndex(item => item === plant)
      let payload = {
        fixed: !plant.fixed,
        vegetable_garden: this.vgarden.id,
        index: plant.id,
        index_store: plantIndex
      }
      this.$store.dispatch('garden/updateVGardenPlant', payload)
          .catch(() => {
            this.$notify({
              color: "danger",
              title: "Erreur",
              text: "Impossible d'enregistrer la position de la plante",
              time: 4000
            })
          })
          .then(() => {
            this.activePlant = this.vgarden.vgarden_plants[plantIndex]
            this.$emit('update-vgarden')
          })
    },
    touchmove(event) {
      let xPos = event.touches[0].clientX - this.vGardenXLength / 2 - 30
      let yPos = event.touches[0].clientY - this.vGardenYLength / 2 - 206
      this.compassAngle = 180 - (this.angleCalc(xPos, yPos) * 180 / Math.PI)
    },
    drag() {
      this.$refs.compass.addEventListener('mousemove', this.move)
    },
    drop() {
      this.$refs.compass.removeEventListener('mousemove', this.move)
    },
    move(event) {
      let xPos = event.offsetX - this.vGardenXLength / 2
      let yPos = event.offsetY - this.vGardenYLength / 2
      this.compassAngle = 180 - (this.angleCalc(xPos, yPos) * 180 / Math.PI)
    },
    angleCalc(xPos, yPos) {
      if (xPos > 0) {
        return Math.acos((yPos) / (Math.sqrt(Math.pow(xPos, 2) + Math.pow(yPos, 2))))
      } else {
        return -Math.acos((yPos) / (Math.sqrt(Math.pow(xPos, 2) + Math.pow(yPos, 2))))
      }
    },
    transmitRemoved() {
      this.activePlant = null
      this.promptActivePlant = false
      this.$emit('update-vgarden')
    },
    transmitUpdate() {
      this.$emit('update-vgarden')
    },

    //OPTIMIZATION
    getInteractionMatrix(plants) {
      const MI = new Array(plants.length);
      for (let i = 0; i < plants.length; i++) {
        const interactions = plants[i].garden_plant.plant.received_interactions
        MI[i] = new Array(plants.length);
        for (let j = 0; j < plants.length; j++) {
          if (interactions) {
            const pl = interactions.find(plant => plant.plant_giver.id === plants[j].garden_plant.plant.id)
            if (pl && pl.positive === true) {
              MI[i][j] = 1
            } else if (pl && pl.positive === false) {
              MI[i][j] = -1
            } else {
              MI[i][j] = 0
            }
          } else {
            MI[i][j] = 0
          }
        }
        MI[i][i] = 0
      }
      return MI
    },

    getCurrentPosition(plants) {

      //find index of fixed plant
      let fix_ids = [], mov_ids = []
      for(let i = 0; i < plants.length; i++) {
        if (plants[i].fixed) {
          fix_ids.push(i);
        }
        else {
          mov_ids.push(i)
        }
      }

      //Define initial position and radius
      const X = new Array(2 * plants.length);
      const x = new Array(2 * mov_ids.length);
      const R = new Array(plants.length);
      for (let i = 0; i < plants.length; i++) {
        if (plants[i].x > this.vgarden.length) {
          plants[i].x = this.vgarden.length
        }
        if (plants[i].x < 0) {
          plants[i].x = 0
        }
        if (plants[i].y > this.vgarden.width) {
          plants[i].y = this.vgarden.width
        }
        if (plants[i].y < 0) {
          plants[i].y = 0
        }
        X[i] = plants[i].x
        X[i + plants.length] = plants[i].y
        R[i] = plants[i].garden_plant.plant.jaya_infos ? plants[i].garden_plant.plant.jaya_infos.planting_radius : 0.5
      }

      //Restrict X0 and R0 to x0 and r0, for moving plants
      mov_ids.map((id,i) => {
        x[i] = X[id]
        x[i + mov_ids.length] = X[id + plants.length]
      })
      return [X, R, x, fix_ids, mov_ids]
    },

    computeAssociationScore(X, R, MI, plants) {
      let assoScore = 0;

      //Compute objective for each plant fixed and not fixed
      for (let i = 0; i < plants.length; i++) {
        for (let j = 0; j < plants.length; j++) {
          const dij2 = Math.pow(X[i] - X[j], 2) + Math.pow(X[i + plants.length] - X[j + plants.length], 2)
          assoScore += -MI[i][j] *
              (plants[i].item_number * R[i]*R[i] + plants[j].item_number*R[j]*R[j])
              / (1 + dij2 / Math.pow(R[i] + R[j], 2))
        }
      }
      return assoScore;
    },

    optimizeCurrentPosition(alignPlantSameSpecy, choiceX0) {
      this.optimisePlacementPopup = true;
      this.infoMessageOptim = [];

      setTimeout(() => {

        this.infoMessageOptim = []
        const nlopt = this.nlopt

        // Get plants
        const plants = this.vgarden.vgarden_plants

        const MI = this.getInteractionMatrix(plants)
        const [X0, R, x0, fix_ids, mov_ids] = this.getCurrentPosition(plants)

        //Temporary pattern
        const size_x = Array.from({length: plants.length}, () => 0)
        const size_y = Array.from({length: plants.length}, () => 0)
        for (let i = 0; i < plants.length; i++) {
          if (plants[i].pattern) {
            size_x[i] = plants[i].pattern.size_x
            size_y[i] = plants[i].pattern.size_y
          }
        }

        //Define algorithm and dimensionnality
        const dim = 2 * mov_ids.length
        const optL = new nlopt.Optimize(nlopt.Algorithm.LN_COBYLA, dim);
        optL.setMaxtime(this.optimMaxTime)

        //Define garden boundaries
        const xmin = Array.from(mov_ids, (i) => {
          return plants[i].pattern ? plants[i].pattern.size_x / 2 : 0
        })
        const ymin = Array.from(mov_ids, (i) => {
          return plants[i].pattern ? plants[i].pattern.size_y / 2 : 0
        })
        const xmax = Array.from(mov_ids, (i) => {
          return plants[i].pattern ? this.vgarden.length - plants[i].pattern.size_x / 2 : this.vgarden.length
        })
        const ymax = Array.from(mov_ids, (i) => {
          return plants[i].pattern ? this.vgarden.width - plants[i].pattern.size_y / 2 : this.vgarden.width
        })
        optL.setLowerBounds(xmin.concat(ymin));
        optL.setUpperBounds(xmax.concat(ymax));

        //CHANGE x0 of free plants
        switch (choiceX0) {
          case "randomGarden":
            mov_ids.map((id,i) => {
              x0[i] = Math.random() * this.vgarden.length
              x0[i + mov_ids.length] = Math.random() * this.vgarden.width
            })
            break;
          case "center":
            mov_ids.map((id,i) => {
              x0[i] = this.vgarden.length/2
              x0[i + mov_ids.length] = this.vgarden.width/2
            })
            break;
          case "centerRandom":
            mov_ids.map((id,i) => {
              x0[i] = (this.vgarden.length/2) + (Math.random()-0.5)/0.5 * 0.01
              x0[i + mov_ids.length] = (this.vgarden.width/2) + (Math.random()-0.5)/0.5 * 0.01
            })
            break;
          default:
            break; //Manual positioning by user
        }

        //OBJECTIVE FUNCTION
        let X = {... X0}
        const objectiveFunction = (x) => {
          //Initialization
          mov_ids.map((id,i) => {
            X[id] = x[i]
            X[id + plants.length] = x[i + mov_ids.length]
          })
          return this.computeAssociationScore(X, R, MI, plants)
        }
        optL.setMinObjective(objectiveFunction, 1e-4);



        //CONSTRAINT COLLIDING
        for (let i = 0; i < plants.length; i++) {
          for (let j = i + 1; j < plants.length; j++) {
            if (!plants[i].fixed || !plants[j].fixed) {
              //If one of both plants is patterned
              if (plants[i].pattern || plants[j].pattern) {
                optL.addInequalityConstraint((x) => {
                  mov_ids.map((id,i) => {
                    X[id] = x[i]
                    X[id + plants.length] = x[i + mov_ids.length]
                  })
                  const area = Math.max(0,
                      (Math.min(X[i] + R[i] + size_x[i]/2, X[j] + R[j] + size_x[j]/2) -
                          Math.max(X[i] - (R[i] + size_x[i]/2), X[j] - (R[j] + size_x[j]/2))) *
                      (Math.min(X[i + plants.length] + R[i] + size_y[i]/2, X[j + plants.length] + R[j] + size_y[j]/2) -
                          Math.max(X[i + plants.length] - (R[i] + size_y[i]/2), X[j + plants.length] - (R[j] + size_y[j]/2))))
                  return area
                }, 1e-4);
              } else {
                optL.addInequalityConstraint((x) => {
                  mov_ids.map((id,i) => {
                    X[id] = x[i]
                    X[id + plants.length] = x[i + mov_ids.length]
                  })
                  const dij = Math.sqrt(Math.pow(X[i] - X[j], 2) + Math.pow(X[i + plants.length] - X[j + plants.length], 2))
                  const rij = (R[i] + R[j])
                  return (1*rij - dij)
                }, 1e-4);
              }
            }
          }
        }

        //OPTIMIZATION
        const res = optL.optimize(x0);

        //POSTPROCESSING
        let xoptim = Array.from({length: plants.length}, (e,i) => X0[i])
        let yoptim = Array.from({length: plants.length}, (e,i) => X0[i+plants.length])
        mov_ids.map((id,i) => {
          xoptim[id] = res.x[i]
          yoptim[id] = res.x[i+mov_ids.length]
        })

        //Center the plants if there is no fixed plant to beautify garden filling
        if (fix_ids.length === 0) {
          const max_x = Math.max(...xoptim.map((x,i) => {
            return x + size_x[i]/2
          }))
          const min_x = Math.min(...xoptim.map((x,i) => {
            return x - size_x[i]/2
          }))
          const max_y = Math.max(...yoptim.map((y,i) => {
            return y + size_y[i]/2
          }))
          const min_y = Math.min(...yoptim.map((y,i) => {
            return y - size_y[i]/2
          }))

          const shift_x = (this.vgarden.length - (max_x - min_x)) / 2 - min_x
          xoptim.forEach((o, i, a) => a[i] = a[i] + shift_x)
          const shift_y = (this.vgarden.width - (max_y - min_y)) / 2 - min_y
          yoptim.forEach((o, i, a) => a[i] = a[i] + shift_y)
        }

        //Save results in vgarden
        plants.map((plant,i) => {
          plant.x = xoptim[i]
          plant.y = yoptim[i]
        })

        //Save results back
        this.updatePlantsPosition()

        let anyInteraction = false
        for (let i = 0; i < plants.length; i++) {
          if(!MI[i].every(item => item === 0)) {
            anyInteraction = true
          }
        }
        if (!anyInteraction && !alignPlantSameSpecy) {
          this.infoMessageOptim.push("Il semble n'y avoir aucune association (bénéfique ou négative) entre les plantes" +
              " de votre potager. L'algorithme d'optimisation n'a pas eu d'effet. " +
              "Utilisez l'outil de cultures associées (onglet 'Plantes') pour ajouter des plantes qui se marient bien.")
        }
        this.optimisePlacementPopup = false;
      }, 500)
    },
    updatePlantsPosition() {
      const plants = this.vgarden.vgarden_plants

      Promise.all(
          plants.map((el, i) => {
            let payload = {
              x: this.vgarden.vgarden_plants[i].x,
              y: this.vgarden.vgarden_plants[i].y,
              vegetable_garden: this.vgarden.id,
              index: this.vgarden.vgarden_plants[i].id,
              index_store: i,
            }
            return this.$store.dispatch('garden/updateVGardenPlant', payload).then(() => {
              if (this.initialSetUp) {
                this.$emit('plant-moved')
                this.emittedUpdate = true
              }
            })
                .catch(() => {
                  this.$notify({
                    color: "danger",
                    title: "Erreur",
                    text: "Impossible d'enregistrer la position de la plante",
                    time: 4000
                  })
                })
          })
      ).then(() => {
        this.$emit('update-vgarden')
      }).catch(() => {
        this.$notify({
          color: "danger",
          title: "Erreur",
          text: "Impossible d'enregistrer les positions des plantes",
          time: 4000
        })
      })
    },
  },
}
</script>

<style lang="scss">
.button-type {
  height: 90px;
  width: 90px;
  border-radius: 20px;
  border: 2px solid rgba(var(--vs-primary), 1);
}

.button-type:active {
  background-color: rgba(var(--vs-secondary), 0.2);
}
</style>
