<template>
  <validation-observer v-slot="{invalid}">
    <b-modal
        v-model="addPlantPrompt"
        :number="true"
        :ok-disabled="invalid || addPlantType===null"
        cancel-title="Annuler"
        cancel-variant="outline-danger"
        centered
        ok-title="Ajouter"
        ok-variant="primary"
        :title="addingPlant.common_name"
        @ok="addPlant">
      <b-col cols="12">
        <b-form-group class="text-center">
          <label class="font-weight-bold font-medium-1 mb-50">
            Type d'ajout
            <JayaTooltip>
              <template #text>
                Pour profiter des pleines performances d'Hortilio, et si vous souhaitez ajouter beaucoup de plantes dans
                votre potager, privilégiez l'ajout par "Rang ou zone" !
                <br><br>
                <strong>Rang ou zone</strong> : ajoute des plants qui seront considérés comme une même entité. Vous
                pourrez positionner, déplacer et gérer cette zone dans son ensemble, sans pouvoir agir sur chacun des
                plants individuels.
                <br><br>
                <strong>Plant individuel</strong> : ajoute des plants qui seront considérés comme des entités séparées.
                Vous pourrez positionner, déplacer et gérer chacun des plants séparément. Cela permet une gestion plus
                fine, mais peut nécéssiter plus de temps.
              </template>
              <feather-icon
                  icon="InfoIcon"
                  size="20"/>
            </JayaTooltip>
          </label>
          <b-form-radio-group v-model="addPlantType">
            <b-form-radio name="addPlantType" value="A" class="m-50">
              Rang ou zone
            </b-form-radio>
            <b-form-radio name="addPlantType" value="S" class="m-50">
              Plant individuel
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="addPlantType==='S'">
        <b-form-group class="text-center">
          <label class="font-weight-bold font-medium-1 mb-50">Nombre de plants</label>
          <validation-provider v-slot="{errors}" :rules="{min_value:1, max_value:10, integer:null}">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <b-form-input
                  v-model="addPlantNumber"
                  max="10"
                  min="1"
                  step="1"
                  style="width: 150px;"
                  type="number"/>
              <b-form-text class="text-danger font-small-3 text-center">{{ errors[0] }}</b-form-text>
            </div>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="addPlantType==='A'">
        <b-form-group class="text-center">
          <label class="font-weight-bold font-medium-1 mb-50">
            Dimension horizontale <span class="font-weight-normal font-italic">(m)</span>
          </label>
          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.length,min_size_value:0, required:{}}">
            <b-form-text class="font-italic">
              Une longueur nulle dans cette direction créé un rang vertical
            </b-form-text>
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="text-left font-weight-bold">Longueur</span>
                </b-input-group-prepend>
                <b-form-input
                    v-model.number="addPlantAreaLength"
                    :max="vGarden.length"
                    :min="0.0"
                    :step="addPlantAreaSpacingX"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-50">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.length,min_size_value: 0.01, required:{}}">
            <div class="d-flex justify-content-center align-items-center" v-if="addPlantAreaLength > 0">
              <b-input-group >
                <b-input-group-prepend is-text>
                  <span class="text-left font-italic">Espacement</span>
                </b-input-group-prepend>
                <b-form-input
                    v-model.number="addPlantAreaSpacingX"
                    :max="addPlantAreaLength"
                    :min="0.0"
                    :step="0.05"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-2" v-if="addPlantAreaLength > 0">{{ errors[0] }}</b-form-text>
          </validation-provider>


          <label class="font-weight-bold font-medium-1 mb-50">
            Dimension verticale <span class="font-weight-normal font-italic">(m)</span>
          </label>
          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.width, min_size_value:0, required:{}}">
            <b-form-text class="font-italic">
              Une longueur nulle dans cette direction créé un rang horizontal
            </b-form-text>
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="text-left font-weight-bold">Largeur</span>
                </b-input-group-prepend>
                <b-form-input
                    v-model.number="addPlantAreaWidth"
                    :max="vGarden.width"
                    :min="0.0"
                    :step="addPlantAreaSpacingY"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-50">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.width,min_size_value:0.01, required:{}}">
            <div class="d-flex justify-content-center align-items-center" v-if="addPlantAreaWidth > 0">
              <b-input-group >
                <b-input-group-prepend is-text>
                  <span class="text-left font-italic"> Espacement </span>
                </b-input-group-prepend>
                <b-form-input
                    v-model.number="addPlantAreaSpacingY"
                    :max="addPlantAreaWidth"
                    :min="0.0"
                    :step="0.05"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-2" v-if="addPlantAreaWidth > 0">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <b-form-text class="text-info pb-50">
            Espacement minimal recommandé : {{100 * plantDiameter}} centimètres
          </b-form-text>

          <b-form-text class="text-info pb-50">


            <strong>{{ nbPlantX * nbPlantY}}
              {{ nbPlantX * nbPlantY > 1 ? "plants" : "plant"}} </strong> au total
            (<strong>{{nbPlantX}}</strong> horizontal et <strong>{{nbPlantY}}</strong> vertical)
          </b-form-text>
        </b-form-group>
      </b-col>
    </b-modal>
  </validation-observer>
</template>

<script>
import {BModal, BCol, BFormGroup, BFormRadioGroup, BFormRadio, BFormInput, BFormText, BInputGroup, BInputGroupPrepend } from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {integer, max_value, min_value, required} from 'vee-validate/dist/rules'

extend('min_value', {...min_value, message: "Vous ne pouvez pas ajouter moins de {min} plant"});
extend('max_value', {...max_value, message: "Vous ne pouvez pas ajouter plus de  {max} plants"});
extend('integer', {...integer, message: "Le champ doit être un nombre entier!"});
extend('max_size_value', {...max_value, message: 'Vous ne pouvez pas dépasser la dimension de votre potager ({max} m)'});
extend('min_size_value', {...min_value, message: 'Valeur minimale: {min}'});
extend('required', {...required, message: 'Ce champ est requis'});

export default {
  name: "VGPlantAdd",
  props: {
    addingPlant: {
      type: Object,
      default: null
    },
    vGarden:  {
      type: Object,
      default: null
    },
  },
  components: {
    BModal,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormText,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      addPlantNumber: 1,
      addPlantType: null,
      addPlantAreaLength: 0.0,
      addPlantAreaWidth: 0.0,
      addPlantAreaSpacingX: Number(this.plantDiameter),
      addPlantAreaSpacingY: Number(this.plantDiameter),
      addPlantPrompt: false,
    }
  },
  computed: {
    plantDiameter() {
      return this.addingPlant.jaya_infos ? 2 * this.addingPlant.jaya_infos.planting_radius : 0
    },
    nbPlantX() {
      return Number(this.addPlantAreaSpacingX) === 0 ? 1 : Math.floor(
          Number(this.addPlantAreaLength) / Number(this.addPlantAreaSpacingX)) +1
    },
    nbPlantY() {
      return Number(this.addPlantAreaSpacingY) === 0 ? 1 : Math.floor(
          Number(this.addPlantAreaWidth) / Number(this.addPlantAreaSpacingY)) +1
    }
  },
  methods: {
    async addPlant() {
      let payload = {
        plant: this.addingPlant.id,
        vegetable_garden: this.vGarden.id,
        x: this.vGarden.length/2,
        y: this.vGarden.width/2,
      }
      if (this.addPlantType === 'S') {
        await Promise.all(
            Array(Number(this.addPlantNumber <= 10 ? this.addPlantNumber : 10)).fill("nonNull")
                .map(async () => {
                   await this.$store.dispatch("garden/addVGardenPlant", payload)
                })
        ).then(() => {
          this.$emit('plant-added')
          this.$notify({
            time: 4000,
            color: "primary",
            title: "Plante(s) ajoutée(s)",
            text: "La plante a bien été ajoutée à votre potager"
          })
        })
      }
      if (this.addPlantType === 'A') {
        if (this.addPlantAreaLength > 0 || this.addPlantAreaWidth > 0) {
          payload.pattern = {
            type : "rectangle",
            size_x: this.addPlantAreaLength,
            spacing_x: Math.max(this.addPlantAreaSpacingX, 0.01),
            size_y:this.addPlantAreaWidth,
            spacing_y: Math.max(this.addPlantAreaSpacingY, 0.01)
          }
        }

        this.$store.dispatch("garden/addVGardenPlant", payload)
            .then(() => {
              this.$emit('plant-added')
              this.$notify({
                time: 4000,
                color: "primary",
                title: "Plante(s) ajoutée(s)",
                text: "La plante a bien été ajoutée à votre potager"
              })
            })
      }
    },
  },
  watch: {
    addingPlant() {
      this.addPlantNumber = 1
      this.addPlantType = null
      this.addPlantAreaLength = 0.0
      this.addPlantAreaWidth = 0.0
      this.addPlantAreaSpacingX = Number(this.plantDiameter)
      this.addPlantAreaSpacingY = Number(this.plantDiameter)
    },
  }
}
</script>
